import store from 'Redux/store';

export const CART_MESSAGE = 'frontend/cart-message/CART_MESSAGE';
export const RESET_CART_MESSAGE = 'frontend/cart-message/RESET_CART_MESSAGE';
export const CART_MESSAGE_DEFAULT = 'NONE';
export const ITEM_UNAVAILABLE = 'ITEM_UNAVAILABLE';
export const E1 = 'E1';

export function setCartMessage(returnData) {
  return store.dispatch({ type: CART_MESSAGE, payload: returnData });
}

export function resetCartMessage() {
  return store.dispatch({ type: RESET_CART_MESSAGE });
}
