import store from 'Redux/store';

export const UNIVERSAL_ERROR_RESET =
  'frontend/universal-error/UNIVERSAL_ERROR_RESET';
export const UNIVERSAL_ERROR_SET =
  'frontend/universal-error/UNIVERSAL_ERROR_SET';

export function universalErrorReset() {
  store.dispatch({ type: UNIVERSAL_ERROR_RESET, payload: null });
}
export function universalErrorSet(data) {
  store.dispatch({ type: UNIVERSAL_ERROR_SET, payload: data });
}
