import store from 'Redux/store';

export const SWITCH_SITE_BRAND = 'frontend/site-theme/SWITCH_SITE_BRAND';

export function switchSiteBrand(value) {
  localStorage.setItem('siteBrand', value);
  store.dispatch({ type: SWITCH_SITE_BRAND, payload: value });
}

export function fetchSiteBrand() {
  return localStorage.getItem('siteBrand') || 'fanatical';
}
