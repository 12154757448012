import get from 'lodash/get';
import { v4 as uuidV4 } from 'uuid';

import env from 'Env';
import State from 'Helpers/State';
import { currencyCode } from 'Helpers/Geo/data';

let lastPage;

const pageView = ({ payload }) => {
  const userId = State.getUserId();
  const pageUrl = `${env.appProtocol}://${env.appUrl}/${window.locale || 'en'}${payload.location.pathname}${payload.location.search}`;

  // Use last logged page as referrer
  const referrer = lastPage || document.referrer || '';

  lastPage = pageUrl;

  return {
    hitType: 'logEvent',
    eventType: 'pageview',
    eventProperties: {
      brand: 'Fanatical',
      // browser is build in by amplitude
      content_id: '',
      external_code: '',
      fastly_session_id: '',
      internal_code: '',
      page_referrer: referrer,
      page_url: pageUrl,
      pageview_id: crypto.randomUUID(),
      registered_user_id: userId,
      title: '', // Doesn't work because pageview is triggered before
      // May need a solution that watches for page title changes
      // before sending the pageview event
      // https://stackoverflow.com/questions/2497200/how-to-listen-for-changes-to-the-title-element/29540461#29540461
    },
  };
};

const addCart = (action) => {
  const pid = get(action, 'payload.itemAddedId', '');
  const cartItems = State.getCartItems();
  const product = cartItems.find(item => item.pid === pid);

  const productName = product.name;
  const gbpPrice = product.price.GBP / 100;
  const localPrice = product.price[currencyCode] / 100;
  const discountPrice = product.local_price / 100;

  let gbpDiscountPrice;
  // Check if there is a discount percentage available for the product
  if (product.current_discount && product.current_discount.percent) {
    gbpDiscountPrice = gbpPrice * (1 - product.current_discount.percent);
  } else {
    // If no discount percentage is available, set the value to null
    gbpDiscountPrice = null;
  }

  return {
    hitType: 'logEvent',
    eventType: 'commerce.cart_add',
    eventProperties: {
      currency: currencyCode,
      product_id: pid,
      product_name: productName,
      local_price: localPrice,
      discount_price: discountPrice,
      gbp_price: gbpPrice,
      gbp_discount_price: gbpDiscountPrice,
    },
  };
};

const removeCart = ({ payload }) => {
  const afterIds = payload.data.map(item => item.product_id || item.pid);
  const removedProducts = payload.cartBeforeEntire.filter(
    item => !afterIds.includes(item.product_id),
  );

  const removedProduct = removedProducts[0];

  if (!removedProduct) {
    return {
      hitType: 'logEvent',
      eventType: 'commerce.cart_remove',
    };
  }

  const localPrice = removedProduct.price / 100;
  const discountPrice = removedProduct.discount_price / 100;

  return {
    hitType: 'logEvent',
    eventType: 'commerce.cart_remove',
    eventProperties: {
      currency: currencyCode,
      product_id: removedProduct.product_id,
      product_name: removedProduct.product_name,
      local_price: localPrice,
      discount_price: discountPrice,
    },
  };
};

const selectPaymentMethod = ({ payload }) => (
  {
    hitType: 'logEvent',
    eventType: 'commerce.select_payment_method',
    eventProperties: {
      order_id: payload.orderId,
      payment_method: payload.method,
      payment_method_is_default: payload.isDefault,
    },
  }
);

const submitPayment = ({ payload }) => {
  const method = payload.method;
  const orderId = payload.orderId;

  const cartItems = State.getCartItems();
  const cartTotalLocal = cartItems.reduce(
    (acc, product) => product.local_price / 100 + acc, 0);
  const cartTotalGbp = cartItems.reduce(
    (acc, product) => product.price.GBP / 100 + acc, 0);

  return {
    hitType: 'logEvent',
    eventType: 'commerce.payment_submit',
    eventProperties: {
      method,
      orderId,
      cart_total_local: cartTotalLocal,
      cart_total_gbp: cartTotalGbp,
    },
  };
};

const createOrder = ({ payload }) => {
  const localDiscountPrices = payload.transactionProducts.reduce(
    (acc, product) => product.local_price / 100 + acc, 0);
  const gbpDiscountPrices = payload.transactionProducts.reduce(
    (acc, product) => product.price / 100 + acc, 0);
  const localPrices = payload.transactionProducts.reduce(
    (acc, product) => product.fullPrice / 100 + acc, 0);
  const gbpPrices = payload.transactionProducts.reduce(
    (acc, product) => product.price / (1 - product.discountPercent) / 100 + acc, 0);

  const productIds = payload.transactionProducts.map(product => product.sku);
  const productNames = payload.transactionProducts.map(product => product.name);

  const orderId = payload.order._id;

  const paymentMethod = payload.paymentMethod;

  return {
    hitType: 'logEvent',
    eventType: 'commerce.order_created',
    eventProperties: {
      currency: currencyCode,
      local_discount_price: localDiscountPrices,
      gbp_discount_price: gbpDiscountPrices,
      product_id: productIds,
      product_name: productNames,
      local_price: localPrices,
      gbp_price: gbpPrices,
      order_id: orderId,
      payment_method: paymentMethod,
    },
  };
};

const completeOrder = ({ payload }) => {
  const localDiscountPrices = payload.transactionProducts.reduce(
    (acc, product) => product.local_price / 100 + acc, 0);
  const gbpDiscountPrices = payload.transactionProducts.reduce(
    (acc, product) => product.price / 100 + acc, 0);
  const localPrices = payload.transactionProducts.reduce(
    (acc, product) => product.fullPrice / 100 + acc, 0);
  const gbpPrices = payload.transactionProducts.reduce(
    (acc, product) => product.price / (1 - product.discountPercent) / 100 + acc, 0);

  const productIds = payload.transactionProducts.map(product => product.sku);
  const productNames = payload.transactionProducts.map(product => product.name);

  const orderId = payload.transactionId;

  return {
    hitType: 'logEvent',
    eventType: 'revenue.order_complete',
    eventProperties: {
      currency: currencyCode,
      local_discount_price: localDiscountPrices,
      gbp_discount_price: gbpDiscountPrices,
      product_id: productIds,
      product_name: productNames,
      local_price: localPrices,
      gbp_price: gbpPrices,
      order_id: orderId,
    },
  };
};

const signUp = ({ payload }) => {
  const userId = payload.userId;
  return {
    hitType: 'logEvent',
    eventType: 'auth.registration_complete',
    eventProperties: {
      user_id: userId,
      reigstered_user_id: userId,
    },
  };
};

const login = ({ payload }) => {
  const userId = payload.userId;

  return {
    hitType: 'logEvent',
    eventType: 'auth.login_complete',
    eventProperties: {
      user_id: userId,
    },
  };
};

const loginStart = () => ({
  hitType: 'logEvent',
  eventType: 'auth.login_start',
});

const registerStart = () => ({
  hitType: 'logEvent',
  eventType: 'auth.registration_start',
});

const newsletterSignup = ({ payload }) => {
  const userId = payload.userId;
  const pageId = payload.pageId;
  const email = payload.email;
  const component = payload.component;

  return {
    hitType: 'logEvent',
    eventType: 'auth.newsletter_subscribe',
    eventProperties: {
      brand: 'Fanatical',
      content_id: '',
      fastly_session_id: '',
      page_referrer: document.referrer || '',
      page_url: pageId,
      pageview_id: uuidV4(),
      registered_user_id: userId,
      title: '',
      email,
      component,
    },
  };
};

const clickInteraction = ({ payload }) => {
  const section = payload.section;
  const value = payload.value;
  const context = payload.context;
  const secondaryContext = payload.secondaryContext;

  return {
    hitType: 'logEvent',
    eventType: 'click',
    eventProperties: {
      section,
      value,
      context,
      secondary_context: secondaryContext,
    },
  };
};

// https://fandom.slack.com/archives/C07PC044MBJ/p1727277632808849?thread_ts=1727195933.715539&cid=C07PC044MBJ

// const splitTestTrigger = ({ payload }) => {
//   const name = payload.experimentName;
//   const variant = payload.variant;
//
//   return {
//     hitType: 'logEvent',
//     eventType: 'splittest',
//     eventProperties: {
//       name,
//       variant,
//     },
//   };
// };

export default {
  '@@router/LOCATION_CHANGE': pageView,
  'frontend/cart/ADD_CART': addCart,
  'frontend/cart/REMOVE_CART': removeCart,
  'frontend/payment/SELECT_PAYMENT_METHOD': selectPaymentMethod,
  'frontend/payment/SUBMIT_PAYMENT': submitPayment,
  'frontend/checkout/CREATE_ORDER': createOrder,
  'frontend/auth/SIGNUP_SUCCESS': signUp,
  'frontend/auth/LOGIN_SUCCESS': login,
  'frontend/auth/SHOW_SIGN_IN': loginStart,
  'frontend/auth/SHOW_REGISTER': registerStart,
  'frontend/email-subscribe/EMAIL_SUBSCRIBE': newsletterSignup,
  'frontend/receipt/COMPLETE_ORDER': completeOrder,
  'frontend/click/CLICK_INTERACTION': clickInteraction,
  // 'frontend/split-test/SPLIT_TEST': splitTestTrigger,
};
