import store from 'Redux/store';

export const RESET_REDEEM_CONFIRM = 'frontend/redeem-confirm/RESET_REDEEM_CONFIRM';
export const NEW_STATE_REDEEM_CONFIRM = 'frontend/redeem-confirm/NEW_STATE_REDEEM_CONFIRM';

export function resetRedeemConfirm() {
  store.dispatch({ type: RESET_REDEEM_CONFIRM, payload: null });
}

export function newRedeemConfirm(statePassed) {
  store.dispatch({ type: NEW_STATE_REDEEM_CONFIRM, payload: statePassed });
}
