import get from 'lodash/get';

import State from 'Helpers/State';
import env from 'Env';

let amplitude = null; // Placeholder for amplitude SDK

// Placeholder for tracked device and user id, to stop sending duplicate
let trackedUserId = null;
let trackedAnonId = null;

const queuedEvents = []; // Events that get triggered before SDK is loaded

// Send event to amplitude (with user/device id if needed)
const processEvent = (event) => {
  const auth = State.getState('auth');
  const anonymous = State.getState('anonymous');

  const userId = get(auth, '_id', null);
  const anonId = get(anonymous, 'id', null);

  if (userId !== trackedUserId && userId) {
    amplitude.setUserId(userId);
    trackedUserId = userId;
  }

  if (anonId !== trackedAnonId && anonId) {
    amplitude.setDeviceId(anonId);
    trackedAnonId = anonId;
  }

  switch (event.hitType) {
    case 'logEvent':
      amplitude.track(event.eventType, event.eventProperties);
      break;
    default:
      break;
  }
};

/**
 * Import and initialise amplitude, then process queued events
 */
function loadAmplitude() {
  import(/* webpackChunkName: "WaveDistance" */'@amplitude/analytics-browser').then((sdk) => {
    amplitude = sdk;
    amplitude.init(env.amplitudeToken, {
      serverUrl: env.aproxy,
      autocapture: {
        pageViews: false,
        attribution: true,
        sessions: true,
      },
    }).promise.then(() => {
      queuedEvents.forEach(processEvent);
      queuedEvents.length = 0;
    });
  }).catch(e => console.warn(e)); // eslint-disable-line no-console
}

/**
 * Redux middleware, queue or process events
 */
export function amplitudeTarget(events) {
  if (!amplitude) {
    queuedEvents.push(...events);
    return;
  }

  events.forEach(processEvent);
}

/**
 * Call when cookie consent is changed or hydrated
 * @param  {Boolean} statisticsAllowed  Are statistics cookies allowed
 */
export function amplitudeConsent(statisticsAllowed) {
  if (statisticsAllowed) {
    if (amplitude) {
      amplitude.setOptOut(false);
    } else {
      loadAmplitude();
    }
  } else if (amplitude) {
    amplitude.setOptOut(true);
  }
}
