import get from 'lodash/get';

import store, { history } from 'Redux/store';

export const TOGGLE_MODAL = 'frontend/modal/TOGGLE_MODAL';
export const CLOSE_MODAL = 'frontend/modal/CLOSE_MODAL';
export const OPEN_MODAL = 'frontend/modal/OPEN_MODAL';
export const LOGIN_MODAL = 'frontend/modal/LOGIN_MODAL';
export const RESET_MODAL = 'frontend/modal/RESET_MODAL';
export const WELCOME_MODAL = 'frontend/modal/WELCOME_MODAL';
export const REDEEM_REQUEST_MODAL = 'frontend/modal/REDEEM_REQUEST_MODAL';
export const WISHLIST_MODAL = 'frontend/modal/WISHLIST_MODAL';
export const WISHLIST_EMPTY_MODAL = 'frontend/modal/WISHLIST_EMPTY_MODAL';
export const CHECKOUT_MODAL = 'frontend/modal/CHECKOUT_MODAL';
export const STEAM_MODAL = 'frontend/modal/STEAM_MODAL';
export const STEAM_EMAIL_MODAL = 'frontend/modal/STEAM_EMAIL_MODAL';
export const UNIVERSAL_ERROR_MODAL = 'frontend/modal/UNIVERSAL_ERROR_MODAL';
export const UBISOFT_MODAL = 'frontend/modal/UBISOFT_MODAL';
export const ORIGIN_MODAL = 'frontend/modal/ORIGIN_MODAL';
export const STEAM_WISHLIST_MODAL = 'frontend/modal/STEAM_WISHLIST_MODAL';
export const LANGUAGE_MODAL = 'frontend/modal/LANGUAGE_MODAL';
export const EXIT_INTENT_MODAL = 'frontend/modal/EXIT_INTENT_MODAL';
export const EXIT_INTENT_MODAL_FANDOM = 'frontend/modal/EXIT_INTENT_MODAL_FANDOM';
export const EXIT_INTENT_MODAL_EMAIL = 'frontend/modal/EXIT_INTENT_MODAL_EMAIL';
export const TWO_FACTOR_MODAL = 'frontend/modal/TWO_FACTOR_MODAL';
export const REGIONAL_RESTRICTIONS_MODAL = 'frontend/modal/REGIONAL_RESTRICTIONS_MODAL';
export const SELECTABLE_FREE_PRODUCT_MODAL = 'frontend/modal/SELECTABLE_FREE_PRODUCT_MODAL';
export const PAY_WHAT_YOU_WANT_MODAL = 'frontend/modal/PAY_WHAT_YOU_WANT_MODAL';
export const PREFERENCE_CENTER_MODAL = 'frontend/modal/PREFERENCE_CENTER_MODAL';
export const GIFT_MODAL = 'frontend/modal/GIFT_MODAL';
export const WISHLIST_SHARE = 'frontend/modal/WISHLIST_SHARE';
export const SIMPLE_MODAL = 'frontend/modal/SIMPLE_MODAL';
export const SUPPORTED_LANGUAGES_MODAL = 'frontend/modal/SUPPORTED_LANGUAGES_MODAL';
export const USER_REVIEW_MODAL = 'frontend/modal/USER_REVIEW_MODAL';
export const GIVEAWAY_EMAIL_REQUIRED_MODAL = 'frontend/modal/GIVEAWAY_EMAIL_REQUIRED_MODAL';
export const VOUCHER_EMAIL_REQUIRED_MODAL = 'frontend/modal/VOUCHER_EMAIL_REQUIRED_MODAL';
export const EPIC_GENBA_MODAL = 'frontend/modal/EPIC_GENBA_MODAL';
export const PAY_WHAT_YOU_WANT_MODAL_DATA = 'frontend/modal/PAY_WHAT_YOU_WANT_MODAL_DATA';
export const REGIONAL_RESTRICTIONS_MODAL_DATA = 'frontend/modal/REGIONAL_RESTRICTIONS_MODAL_DATA';
export const SELECTABLE_FREE_PRODUCT_MODAL_DATA = 'frontend/modal/SELECTABLE_FREE_PRODUCT_MODAL_DATA';
export const GIFT_MODAL_DATA = 'frontend/modal/GIFT_MODAL_DATA';
export const SIMPLE_MODAL_DATA = 'frontend/modal/SIMPLE_MODAL_DATA';
export const SUPPORTED_LANGUAGES_MODAL_DATA = 'frontend/modal/SUPPORTED_LANGUAGES_MODAL_DATA';
export const USER_REVIEW_MODAL_DATA = 'frontend/modal/USER_REVIEW_MODAL_DATA';
export const GIVEAWAY_EMAIL_REQUIRED_MODAL_DATA = 'frontend/modal/GIVEAWAY_EMAIL_REQUIRED_MODAL_DATA';
export const VOUCHER_EMAIL_REQUIRED_MODAL_DATA = 'frontend/modal/VOUCHER_EMAIL_REQUIRED_MODAL_DATA';
export const EMAIL_SUBSCRIBE_MODAL = 'frontend/modal/EMAIL_SUBSCRIBE_MODAL';
export const WEB_PUSH_SUBSCRIBE_MODAL = 'frontend/modal/WEB_PUSH_SUBSCRIBE_MODAL';

export function toggleModal(name, page, data) {
  const payload = { name, page: page || '', data };
  const state = store.getState();
  const pathname = get(state, 'router.location.pathname');
  // need to move away from account page if closing login modal not having logged in
  if (pathname === '/account' && name === 'frontend/modal/LOGIN_MODAL') {
    const modal = get(state, 'modal');
    const loginModalOpen = get(modal, 'frontend/modal/LOGIN_MODAL');
    const authenticated = get(state, 'auth.authenticated');
    if (loginModalOpen && !authenticated) {
      history.push('/');
    }
  }
  return { type: TOGGLE_MODAL, payload };
}

export function closeModal(name) {
  return { type: CLOSE_MODAL, payload: name };
}

export function openModal(name, page) {
  const payload = { name, page: page || '' };
  return { type: OPEN_MODAL, payload };
}

export function toggleLoginModal(page) {
  const state = store.getState();
  const auth = get(state, 'auth');
  if (!auth.authenticated) {
    store.dispatch({ type: 'frontend/auth/RESET_AUTH' });
  }
  store.dispatch({ type: 'frontend/google/GOOGLE_ERROR', payload: null });
  return toggleModal(LOGIN_MODAL, page);
}

export function closeLoginModal() {
  const state = store.getState();
  const auth = get(state, 'auth');
  if (!auth.authenticated) {
    store.dispatch({ type: 'frontend/auth/RESET_AUTH' });
  }
  return closeModal(LOGIN_MODAL);
}

export function openLoginModal(page) {
  return openModal(LOGIN_MODAL, page);
}

export function toggleResetModal() {
  return toggleModal(RESET_MODAL);
}

export function toggleRedeemRequestModal() {
  return toggleModal(REDEEM_REQUEST_MODAL);
}

export function toggleWishlistModal() {
  return toggleModal(WISHLIST_MODAL);
}

export function toggleWishlistEmptyModal() {
  return toggleModal(WISHLIST_EMPTY_MODAL);
}

export function toggleCheckoutModal() {
  return toggleModal(CHECKOUT_MODAL);
}

export function toggleSteamModal() {
  return toggleModal(STEAM_MODAL);
}

export function closeSteamModal() {
  return closeModal(STEAM_MODAL);
}

export function toggleUniversalErrorModal() {
  return toggleModal(UNIVERSAL_ERROR_MODAL);
}

export function toggleUbisoftModal() {
  return toggleModal(UBISOFT_MODAL);
}

export function toggleLanguageModal() {
  return toggleModal(LANGUAGE_MODAL);
}

export function toggleExitIntentFandom() {
  return toggleModal(EXIT_INTENT_MODAL_FANDOM);
}

export function toggleExitIntentEmail() {
  return toggleModal(EXIT_INTENT_MODAL_EMAIL);
}

export function toggleExitIntentStarDeal() {
  return toggleModal(EXIT_INTENT_MODAL);
}

export function closeExitIntentStarDeal() {
  return closeModal(EXIT_INTENT_MODAL);
}

export function openExitIntentStarDeal() {
  return openModal(EXIT_INTENT_MODAL);
}

export function toggleRegionalRestrictionsModal(data = {}) {
  return toggleModal(REGIONAL_RESTRICTIONS_MODAL, '', data);
}

export function toggleSelectableFreeProductsModal(data) {
  return toggleModal(SELECTABLE_FREE_PRODUCT_MODAL, '', data);
}

export function togglePayWhatYouWantModal(data) {
  return toggleModal(PAY_WHAT_YOU_WANT_MODAL, '', data);
}

export function togglePreferenceCenterModal() {
  return toggleModal(PREFERENCE_CENTER_MODAL);
}

export function toggleGiftModal(data) {
  return toggleModal(GIFT_MODAL, '', data);
}

export function toggleSupportLanguagesModal(data = {}) {
  return toggleModal(SUPPORTED_LANGUAGES_MODAL, '', data);
}

export function toggleEpicGenbaModal(data = {}) {
  return toggleModal(EPIC_GENBA_MODAL, '', data);
}

export function toggleGiveawayEmailRequiredModal(data = {}) {
  return toggleModal(GIVEAWAY_EMAIL_REQUIRED_MODAL, '', data);
}

export function toggleVoucherEmailRequiredModal(data = {}) {
  return toggleModal(VOUCHER_EMAIL_REQUIRED_MODAL, '', data);
}

export function toggleEmailSubscribeModal(data = {}) {
  return toggleModal(EMAIL_SUBSCRIBE_MODAL, '', data);
}

export function toggleWebPushSubscribeModal(data = {}) {
  return toggleModal(WEB_PUSH_SUBSCRIBE_MODAL, '', data);
}
