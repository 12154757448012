import store from 'Redux/store';

export const HAS_LOADED = 'frontend/recaptcha/HAS_LOADED';
export const HAS_V3_LOADED = 'frontend/recaptcha/HAS_V3_LOADED';

export function hasLoaded() {
  store.dispatch({ type: HAS_LOADED, payload: true });
}

export function hasV3Loaded(token) {
  store.dispatch({ type: HAS_V3_LOADED, payload: token });
}
