import { post } from 'Api';
import store from 'Redux/store';
import { refreshAuth } from 'Redux/ducks/auth';

export const SHOW_PROMPT = 'frontend/languages/SHOW_PROMPT';
export const CHANGE_LANGUAGE = 'frontend/languages/CHANGE_LANGUAGE';

export const setBackendLanguage = async (language) => {
  if (store.getState().auth && store.getState().auth.authenticated) {
    await post(null, '/user/set-language', {
      language,
    });
    await store.dispatch(refreshAuth());
  }
};
