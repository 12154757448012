import get from 'lodash/get';

import store from 'Redux/store';
import { refreshAuth } from 'Redux/ducks/auth';

export const DO_LOAD = 'frontend/initial/DO_LOAD';
export const CHECK_HAS_LOGGED_IN = 'frontend/initial/CHECK_HAS_LOGGED_IN';

export function doLoad() {
  if (store) {
    store.dispatch({ type: DO_LOAD, payload: true });
  }
}

export function checkHasLoggedIn() {
  if (store) {
    const userId = get(store.getState(), 'auth._id', false);
    let hasLoggedIn = false;

    if (userId) {
      localStorage.setItem('hasLoggedIn', true);

      store.dispatch(refreshAuth());
    }

    if (localStorage.getItem('hasLoggedIn')) {
      hasLoggedIn = true;
    }

    store.dispatch({ type: CHECK_HAS_LOGGED_IN, payload: hasLoggedIn });
  }
}
