import store from 'Redux/store';

export const OPTIN_MESSAGE = 'frontend/cart-message/OPTIN_MESSAGE';
export const RESET_OPTIN_MESSAGE = 'frontend/cart-message/RESET_OPTIN_MESSAGE';
export const OPTIN_MESSAGE_DEFAULT = 'OPTIN_MESSAGE_DEFAULT';
export const OPTIN_MESSAGE_SUCCESS = 'OPTIN_MESSAGE_SUCCESS';
export const OPTIN_MESSAGE_ERROR = 'OPTIN_MESSAGE_ERROR';

export function setOptinMessage(returnData) {
  return store.dispatch({ type: OPTIN_MESSAGE, payload: returnData });
}

export function setOptinSuccess() {
  return store.dispatch({ type: OPTIN_MESSAGE_SUCCESS, payload: null });
}

export function setOptinError() {
  return store.dispatch({ type: OPTIN_MESSAGE_ERROR, payload: null });
}

export function resetOptinMessage() {
  return store.dispatch({ type: RESET_OPTIN_MESSAGE });
}
