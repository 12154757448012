import get from 'lodash/get';

import { post, get as apiGet, del } from 'Api';
import { postEmail } from 'Redux/ducks/email-subscribe';
import { RESET_ACCOUNT_CONFIRM, NEW_STATE_ACCOUNT_CONFIRM } from 'Redux/ducks/account-confirm';
import { RESET_PASSWORD_RESET, NEW_STATE_PASSWORD_RESET } from 'Redux/ducks/password-reset';
import store, { history } from 'Redux/store';
import { toggleLoginModal, openLoginModal, toggleVoucherEmailRequiredModal } from 'Redux/ducks/modal';
import { fetchCart } from 'Redux/ducks/cart';
import { clearWishlist, fetchWishlist } from 'Redux/ducks/wishlist';
import { afterLoginActions } from 'Redux/ducks/login-actions';
import { newStateAtok } from 'Redux/ducks/atok';
import State from 'Helpers/State';
import CheckoutUtils from 'Helpers/Checkout/CheckoutUtils';
import GeneralUtils from 'Helpers/GeneralUtils';
import { GenerateV3Token } from 'Helpers/Recaptcha/Recaptcha';
import LightboxUtils from 'Helpers/Lightbox/LightboxUtils';
import { toggleFeedbackPopup } from 'Redux/ducks/feedback-popups';
import { setGiftOrder } from 'Redux/ducks/gift';

export const LOGIN_SUCCESS = 'frontend/auth/LOGIN_SUCCESS';
export const SIGNUP_SUCCESS = 'frontend/auth/SIGNUP_SUCCESS';
export const LOGIN_ERROR = 'frontend/auth/LOGIN_ERROR';
export const SIGNUP_ERROR = 'frontend/auth/SIGNUP_ERROR';
export const CHALLENGE = 'frontend/auth/CHALLENGE';
export const MAGIC_SUMMONED = 'frontend/auth/MAGIC_SUMMONED';
export const RESET_AUTH = 'frontend/auth/RESET_AUTH';
export const REFRESH_AUTH = 'frontend/auth/REFRESH_AUTH';
export const RESET_SUCCESS = 'frontend/auth/RESET_SUCCESS';
export const CHANGE_PASSWORD_SUCCESS = 'frontend/auth/CHANGE_PASSWORD_SUCCESS';
export const SEND_CONFIRM_EMAIL = 'frontend/auth/SEND_CONFIRM_EMAIL';
export const CHECK_CONFIRMED_EMAIL = 'frontend/auth/CHECK_CONFIRMED_EMAIL';
export const CHANGE_EMAIL_SUCCESS = 'frontend/auth/CHANGE_EMAIL_SUCCESS';
export const NEW_CONFIRMED_EMAIL = 'frontend/auth/NEW_CONFIRMED_EMAIL';
export const SAVE_STEAM_PROFILE = 'frontend/auth/SAVE_STEAM_PROFILE';
export const SAVE_STEAM_PROFILE_ERROR = 'frontend/auth/SAVE_STEAM_PROFILE_ERROR';
export const EMAIL_CHANGE_CONFIRM = 'frontend/auth/EMAIL_CHANGE_CONFIRM';
export const DETECT_KY = 'frontend/auth/DETECT_KY';
export const DETECT_HONEY = 'frontend/auth/DETECT_HONEY';
export const SET_LOGGED_OUT_EMAIL = 'frontend/auth/SET_LOGGED_OUT_EMAIL';
export const SET_NEWSLETTER = 'frontend/auth/SET_NEWSLETTER';
export const SAVE_BILLING_SUCCESS = 'frontend/auth/SAVE_BILLING_SUCCESS';
export const SAVE_BILLING_ERROR = 'frontend/auth/SAVE_BILLING_ERROR';
export const SET_LOCAL_NEWSLETTER_CONSENT = 'frontend/auth/SET_LOCAL_NEWSLETTER_CONSENT';
export const SAVE_DOB = 'frontend/auth/SAVE_DOB';
export const SAVE_SITE_PREFERENCES = 'frontend/auth/SAVE_SITE_PREFERENCES';

export const initialState = {
  sentEmail: false,
  authenticated: false,
  preferences: {},
};

/* eslint-disable consistent-return */
/* we do not want to update the auth if there are no changes */
export function refreshAuth() {
  return async (dispatch) => {
    const { response, data } = await apiGet(dispatch, '/user/refresh-auth');

    if (response.ok) {
      LightboxUtils.setLightboxVars();

      return dispatch({ type: REFRESH_AUTH, payload: data });
    }
  };
}

export function deleteBillingAddress() {
  return async (dispatch) => {
    const { response } = await del(dispatch, '/user/delete-billing-address');
    if (response.ok) {
      return dispatch(refreshAuth());
    }
  };
}

/* we do not want to update the auth if there are no changes */
export function unlinkSteam() {
  return async (dispatch) => {
    const { response, data } = await apiGet(dispatch, '/user/unlink-steam');

    if (response.ok) {
      return dispatch({ type: REFRESH_AUTH, payload: data });
    }
  };
}

/* fetch users owned games list from steam */
export function getUsersOwnedSteamGames(steamId) {
  return async (dispatch) => {
    const { response, data } = await apiGet(dispatch, `/user/owned-games-steam/${steamId}`);

    if (response.ok) return dispatch({ type: REFRESH_AUTH, payload: data });

    throw Error('ERROR getUsersOwnedSteamGames', data.error);
  };
}

// Fetch User Profile
export function authorizeSteam(steamId) {
  return async (dispatch) => {
    const { response, data } = await apiGet(dispatch, `/user/check-users-steam/${steamId}`);
    if (response.ok) {
      if (get(data, 'steam.privacyState', '') === 'public') {
        await store.dispatch(getUsersOwnedSteamGames(data.steam.steam_id));
      }
      if (get(data, 'steam.privacyState', '') === 'public') {
        history.replace({ pathname: '/account/login' });
      }
      return dispatch({ type: REFRESH_AUTH, payload: data });
    }

    throw Error('ERROR authorizeSteam', data.error);
  };
}

export function unlinkEpic() {
  return async (dispatch) => {
    const { response, data } = await apiGet(dispatch, '/user/unlink-epic');

    if (response.ok) {
      return dispatch({ type: REFRESH_AUTH, payload: data });
    }
  };
}

export function toggleRoktAds(roktAds) {
  return async (dispatch) => {
    const { response, data } = await post(dispatch, '/user/preference-rokt-ads', {
      roktAds,
    });

    if (response.ok) {
      return dispatch({ type: REFRESH_AUTH, payload: data });
    }
  };
}

export function toggleWishlistNotifications(wishlistNotifications) {
  return async (dispatch) => {
    const { response, data } = await post(dispatch, '/user/updatewishlistnotifications', {
      wishlistNotifications,
    });

    if (response.ok) {
      return dispatch({ type: REFRESH_AUTH, payload: data });
    }
  };
}

export function toggleCartNotifications(cartNotifications) {
  return async (dispatch) => {
    const { response, data } = await post(dispatch, '/user/updatecartnotifications', {
      cartNotifications,
    });

    if (response.ok) {
      return dispatch({ type: REFRESH_AUTH, payload: data });
    }
  };
}

export function toggleReviewReminders(reviewReminders) {
  return async (dispatch) => {
    const { response, data } = await post(dispatch, '/user/updatereviewreminders', {
      reviewReminders,
    });

    if (response.ok) {
      return dispatch({ type: REFRESH_AUTH, payload: data });
    }
  };
}

export function toggleUserReviewReminders(userReviewReminders) {
  return async (dispatch) => {
    const { response, data } = await post(dispatch, '/user/updateuserreviewreminders', {
      userReviewReminders,
    });

    if (response.ok) {
      return dispatch({ type: REFRESH_AUTH, payload: data });
    }
  };
}

export function toggleKeyRedeemReminders(keyRedeemReminders) {
  return async (dispatch) => {
    const { response, data } = await post(dispatch, '/user/updatekeyrevealreminders', {
      keyRedeemReminders,
    });

    if (response.ok) {
      return dispatch({ type: REFRESH_AUTH, payload: data });
    }
  };
}

export function finishLogin(data, isMagic = false, isRegister = false) {
  const user = data;
  LightboxUtils.setLightboxVars();

  const payload = {
    ...user,
    userId: user._id,
    pageId: State.getPageId(),
    isMagic,
  };

  localStorage.setItem('hasLoggedIn', true);

  const { dispatch } = store;

  if (isRegister) {
    dispatch({ type: SIGNUP_SUCCESS, payload });
  } else {
    dispatch({ type: LOGIN_SUCCESS, payload });
  }

  return window.setTimeout(() => {
    dispatch(fetchCart());
    dispatch(fetchWishlist());
    dispatch(afterLoginActions(isRegister));
    CheckoutUtils.authComplete();
  }, 200);
}

/**
 * Dispatch error
 * @param  {String} code
 */
const authError = code => store.dispatch({ type: LOGIN_ERROR, payload: { code } });

export function submitChallenge(token, challengeId, type, v3RecaptchaToken) {
  const state = store.getState();

  let urlType = 'recaptcha';
  if (type === 'twoFactor') {
    urlType = 'two-factor';
  }
  if (type === 'suspiciousCountry') {
    urlType = 'suspicous-country';
  }

  return async (dispatch) => {
    const { response, data } = await post(dispatch, `/auth/${urlType}`, {
      token,
      challengeId,
      v3_recaptcha: v3RecaptchaToken,
      snowplowId: GeneralUtils.getSnowplowId(),
      language: window.locale,
    });

    if (response.status === 200) {
      if (data.magicSummoned) {
        return dispatch({ type: MAGIC_SUMMONED, payload: data });
      }
      if (data.tokenFound) {
        localStorage.setItem('bsatok', data.tokenFound);
        delete data.tokenFound;
      }
      return finishLogin(data);
    }

    if (response.status === 202) {
      return dispatch({ type: CHALLENGE, payload: data });
    }

    if (response.status === 404) {
      return dispatch({ type: CHALLENGE, payload: null });
    }

    if (response.status === 400) {
      if (data && data.code === 'E201') {
        history.replace({
          pathname: '/new-password',
          state: state.auth.email,
        });
        return dispatch(toggleLoginModal());
      }
      return dispatch({ type: LOGIN_ERROR, payload: data });
    }

    if (response.status === 429) {
      return authError('E501');
    }

    return authError('E502');
  };
}

const emailRegex = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;

export function newLogin(email, password, rememberMe) {
  store.dispatch({ type: SET_LOGGED_OUT_EMAIL, payload: email });

  if (!email || typeof email !== 'string') {
    return authError('E001');
  }

  if (!emailRegex.test(email)) {
    return authError('E002');
  }

  if (!password || typeof password !== 'string') {
    return authError('E003');
  }

  const atok = localStorage.getItem('bsatok');

  return async (dispatch) => {
    const { response, data } = await post(dispatch, '/auth/login', {
      email,
      atok,
      password: password.trim(),
      v3_recaptcha: await GenerateV3Token('login'),
      snowplowId: GeneralUtils.getSnowplowId(),
      dontRememberMe: !rememberMe,
    });

    if (response.status === 200) {
      return finishLogin(data);
    }

    if (response.status === 202) {
      return dispatch({ type: CHALLENGE, payload: data });
    }

    if (response.status === 400) {
      if (data && data.code === 'E201') {
        history.replace({
          pathname: '/new-password',
          state: email,
        });
        return dispatch(toggleLoginModal());
      }
      return dispatch({ type: LOGIN_ERROR, payload: data });
    }

    if (response.status === 429) {
      return authError('E501');
    }

    return authError('E502');
  };
}

export function summonMagic(email) {
  store.dispatch({ type: SET_LOGGED_OUT_EMAIL, payload: email });

  const atok = localStorage.getItem('bsatok');

  if (!email || typeof email !== 'string') {
    return authError('E001');
  }

  if (!emailRegex.test(email)) {
    return authError('E002');
  }

  return async (dispatch) => {
    const { response, data } = await post(dispatch, '/auth/magic/summon', {
      email,
      atok,
      v3_recaptcha: await GenerateV3Token('login'),
      snowplowId: GeneralUtils.getSnowplowId(),
    });

    if (response.status === 200) {
      return dispatch({ type: MAGIC_SUMMONED, payload: data });
    }

    if (response.status === 202) {
      return dispatch({ type: CHALLENGE, payload: data });
    }

    if (response.status === 429) {
      return authError('E501');
    }

    return authError('E502');
  };
}

export function castMagic(spell) {
  const atok = localStorage.getItem('bsatok');

  return async (dispatch) => {
    const { response, data } = await post(dispatch, '/auth/magic/cast', {
      spell,
      atok,
      v3_recaptcha: await GenerateV3Token('login'),
      snowplowId: GeneralUtils.getSnowplowId(),
    });

    if (response.status === 200) {
      return finishLogin(data, true);
    }

    if (response.status === 202) {
      dispatch(openLoginModal());
      return dispatch({ type: CHALLENGE, payload: data });
    }

    if (response.status === 429) {
      return authError('E501');
    }

    if (response.status >= 400 && response.status < 500) {
      return dispatch({ type: LOGIN_ERROR, payload: { message: 'There was a problem with your magic password link' } });
    }

    return authError('E502');
  };
}

export function newRegister(email, password, rememberMe, newsletter) {
  if (!email || typeof email !== 'string') {
    return authError('E001');
  }

  if (!emailRegex.test(email)) {
    return authError('E002');
  }

  if (!password || typeof password !== 'string') {
    return authError('E003');
  }

  return async (dispatch) => {
    const { response, data } = await post(dispatch, '/auth/register', {
      email,
      password: password.trim(),
      v3_recaptcha: await GenerateV3Token('signup'),
      snowplowId: GeneralUtils.getSnowplowId(),
      dontRememberMe: !rememberMe,
      language: window.locale,
    });

    if (response.status === 200) {
      dispatch(postEmail(email, 'New Account', true, newsletter));
      return finishLogin(data, false, true);
    }

    if (response.status === 202) {
      dispatch(postEmail(email, 'New Account', true, newsletter));
      return dispatch({ type: CHALLENGE, payload: data });
    }

    if (response.status === 400) {
      if (data && data.code === 'E201') {
        history.replace({
          pathname: '/new-password',
          state: email,
        });
        return dispatch(toggleLoginModal());
      }
      return dispatch({ type: LOGIN_ERROR, payload: data });
    }

    if (response.status === 429) {
      return authError('E501');
    }

    return authError('E502');
  };
}

export function doPreCheck() {
  return async (dispatch) => {
    await apiGet(dispatch, '/auth/pre-check');
  };
}

export function logout() {
  return async (dispatch) => {
    setGiftOrder({});
    clearWishlist();
    LightboxUtils.removeLightboxVars();
    try {
      await del(dispatch, '/auth/logout');
    } catch (e) {
      console.warn('Logout error', e); // eslint-disable-line no-console
    }

    dispatch({ type: 'frontend/auth/LOGOUT', payload: null });
    dispatch({ type: 'frontend/orders/CLEAR_ORDERS', payload: null });


    if (get(history, 'location.pathname', '').startsWith('/account')) {
      history.replace({
        pathname: '/',
      });
    }
  };
}

export function logOutAll() {
  return async (dispatch) => {
    clearWishlist();
    setGiftOrder({});
    LightboxUtils.removeLightboxVars();
    try {
      await del(dispatch, '/auth/logout-all');
    } catch (e) {
      console.warn('Logout error', e); // eslint-disable-line no-console
    }

    dispatch({ type: 'frontend/auth/LOGOUT', payload: null });
    dispatch({ type: 'frontend/orders/CLEAR_ORDERS', payload: null });

    history.replace({
      pathname: '/',
    });
  };
}

export function resetEmail(username) {
  const checkedUsername = username.email;
  return async (dispatch) => {
    await post(dispatch, '/password-reset', { checkedUsername });
  };
}

export function reset(username, captcha, v3RecaptchaToken) {
  return async (dispatch) => {
    const { response, data } = await post(dispatch, '/password/reset', {
      email: username,
      recaptcha: captcha,
      anonId: State.getAnonId(),
      v3_recaptcha: v3RecaptchaToken,
    });

    if (!username) {
      return 'Please enter an email address.';
    }

    if (!captcha) {
      return 'Invalid ReCaptcha';
    }

    if (response.ok) return { type: RESET_SUCCESS, payload: data };

    let error = 'Unable to reset account. Please try again later.';
    if (data === 'Invalid ReCaptcha - E01' || data === 'Invalid ReCaptcha - E02') {
      error = data;
    }
    if (data.error) { error = 'Please enter an email address.'; }
    if (data.err) { error = 'Invalid ReCaptcha'; }

    return error;
  };
}

export function doActualPasswordReset(token, password) {
  const url = `/password/reset/${token}`;
  return async (dispatch) => {
    const { response, data } = await post(dispatch, url, {
      password: password.trim(),
    });

    window.setTimeout(() => {
      dispatch({ type: RESET_PASSWORD_RESET, payload: {} });
    }, 2 * 60 * 1000);
    const returnObject = {
      status: 99,
      error: '',
    };
    if (response.status >= 400) {
      returnObject.status = response.status;
      const baseError = 'Sorry we cannot reset your password at this time please try again';
      returnObject.error = baseError;
      if (data.error) {
        returnObject.error = data.error;
        if (returnObject.error === 'Failed to verify token'
          || returnObject.error === 'Invalid token') {
          returnObject.error = 'Sorry, your reset password link has expired. Please reset your' +
            ' password again to receive another email';
        }
        if (returnObject.error === 'Sift bad') {
          returnObject.error = baseError;
        }
      }

      return dispatch({ type: NEW_STATE_PASSWORD_RESET, payload: returnObject });
    }

    if (data.atok) dispatch(newStateAtok(JSON.stringify(data.newAtok)));

    const publicUser = get(data, 'publicUser');

    if (!publicUser) {
      return dispatch({ type: NEW_STATE_PASSWORD_RESET, payload: returnObject });
    }

    const payload = {
      data: publicUser,
      userId: get(publicUser, '_id', ''),
      pageId: State.getPageId(),
    };

    localStorage.setItem('hasLoggedIn', true);

    dispatch({ type: LOGIN_SUCCESS, payload });

    return window.setTimeout(() => {
      dispatch(fetchCart());
      dispatch(fetchWishlist());
      dispatch(afterLoginActions());
      CheckoutUtils.authComplete();
      dispatch({ type: NEW_STATE_PASSWORD_RESET, payload: returnObject });
    }, 200);
  };
}

export function changePassword(currentPassword, newPassword, confirmPassword) {
  return async (dispatch) => {
    const { response, data } = await post(dispatch, '/user/change-password', {
      password: currentPassword,
      new_password: newPassword,
      repeat_password: confirmPassword,
    });

    if (response.ok) {
      dispatch({ type: CHANGE_PASSWORD_SUCCESS, payload: true });
      setTimeout(() => {
        dispatch({ type: CHANGE_PASSWORD_SUCCESS, payload: false });
      }, 5000);
      return dispatch({ type: REFRESH_AUTH, payload: data });
    }

    let error;
    if (data === 'Password used previously') {
      error = 'You have previously used this password. Please try a different one.';
      throw Error(error);
    }

    switch (response.status) {
      case 400:
        error = 'Your passwords do not match. Please try again.';
        break;

      case 406:
        error = 'Unable to change password. Please check your details and try again.';
        break;

      default:
        error = 'There was an issue updating your password. Please try again.';
    }

    throw Error(error);
  };
}

export function sendConfirmEmail() {
  return async (dispatch) => {
    const { response, data } = await post(dispatch, '/user/confirm');

    if (response.ok) return dispatch({ type: SEND_CONFIRM_EMAIL, payload: true });

    throw Error(data.error);
  };
}

export function changeEmail(newEmail, oldEmail, recaptcha) {
  return async (dispatch) => {
    const { response, data } = await post(dispatch, '/user/new-change-email', {
      new_email: newEmail,
      old_email: oldEmail,
      username: oldEmail,
      recaptcha,
    });

    if (response.ok) {
      dispatch({ type: LOGIN_SUCCESS, payload: { data } });
      dispatch({ type: SEND_CONFIRM_EMAIL, payload: true });
      return;
    }

    let error;
    switch (response.status) {
      case 400:
        error = 'Please try again.';
        if (data === 'Not a valid email') error = 'Invalid email address. Please try again';
        if (data === 'Duplicate email') error = 'That email already exists. Please try again';
        break;
      case 406:
        error = 'Please enter an email address';
        break;
      default:
        error = 'Please try again.';
    }
    throw Error(error);
  };
}

export function checkConfirmed() {
  return async (dispatch) => {
    const { response, data } = await apiGet(dispatch, '/user/confirm/verify');

    if (!data.email_confirmed) {
      window.setTimeout(() => {
        dispatch({ type: CHECK_CONFIRMED_EMAIL, payload: { failed_check: false } });
      }, 10000);
      return dispatch({ type: CHECK_CONFIRMED_EMAIL, payload: { failed_check: true } });
    }
    if (response.ok && data.email_confirmed) {
      return dispatch({
        type: CHECK_CONFIRMED_EMAIL,
        payload: { ...data, failed_check: false },
      });
    }

    throw Error(data);
  };
}

/**
 * Peform a check to see if the user has confirmed their email without needing to be dispatched
 * @return {Boolean} status of user email confirmed
 */
export async function checkConfirmedWithoutDispatch() {
  let emailConfirmed = false;
  const { response, data } = await apiGet([], '/user/confirm/verify');

  if (response.ok) emailConfirmed = data.email_confirmed;
  if (!response.ok) throw Error(data);

  return emailConfirmed;
}

export function emailChangeConfirm(token) {
  return async (dispatch) => {
    const { data } = await post(dispatch, '/usern/email-change-confirm', { token });
    dispatch({ type: NEW_STATE_ACCOUNT_CONFIRM, payload: data });
    return dispatch(refreshAuth());
  };
}

export function accountConfirm(token1, token2, date) {
  return async (dispatch, getState) => {
    const { response, data } = await post(dispatch, '/usern/newconfirm', { token1, token2, date });
    const state = getState();
    const auth = state.auth || {};
    if (data.status === 0 && response.ok && auth._id) {
      dispatch({ type: NEW_CONFIRMED_EMAIL });
    }
    if (data.status === 99 && response.ok && auth._id) {
      dispatch(refreshAuth());
    }
    window.setTimeout(() => {
      dispatch({ type: RESET_ACCOUNT_CONFIRM, payload: {} });
    }, 2 * 60 * 1000);
    return dispatch({ type: NEW_STATE_ACCOUNT_CONFIRM, payload: data });
  };
}

export function saveSteamProfile(token) {
  return async (dispatch) => {
    const { response, data } = await post(dispatch, '/user/link-steam', { token });
    if (!response.ok) {
      if (response.status === 429) {
        window.location.search = `?message=${encodeURIComponent('Too many attempts to connect steam. Please wait and try again in an hour')}`;
      } else if (data.err) {
        window.location.search = `?message=${encodeURIComponent(data.err)}`;
      } else {
        window.location.search = `?message=${encodeURIComponent('Unexpected error connecting steam. Please try again')}`;
      }
      return dispatch({ type: SAVE_STEAM_PROFILE_ERROR, payload: data });
    }
    return dispatch({ type: SAVE_STEAM_PROFILE, payload: data });
  };
}

export function setNewsletter(status) {
  if (typeof status !== 'boolean') {
    return;
  }
  const state = store.getState();
  const auth = state.auth;
  auth.email_newsletter = status;
  return store.dispatch({ type: SET_NEWSLETTER, payload: auth });
}

export async function saveBilling(billing) {
  const { response } = await post(store.dispatch, '/user/save-billing', { billing });
  return response;
}

export function setLocalNewletterConsent(ticked, continueToPayment, giveaway) {
  store.dispatch({
    type: SET_LOCAL_NEWSLETTER_CONSENT,
    payload: ticked ? Date.now() : 0,
  });
  // If they are unticking don't continue
  if (!ticked) {
    return true;
  }
  if (!giveaway) {
    store.dispatch(toggleVoucherEmailRequiredModal());
  }
  if (continueToPayment) {
    CheckoutUtils.continueToPayment();
  }
  return true;
}

export async function saveDOB(dob) {
  const { response } = await post(store.dispatch, '/user/save-dob', { dob });
  const state = store.getState();
  const auth = state.auth;
  // To get around react props issues save as string to redux
  auth.dob = dob.toISOString();
  store.dispatch({ type: SAVE_DOB, payload: auth });
  return response;
}

export function deleteAccount() {
  return async (dispatch) => {
    const { response } = await del(dispatch, '/user/delete-account');
    if (response && response.ok) {
      history.push('/');
      dispatch({ type: 'frontend/auth/LOGOUT', payload: null });
      dispatch({ type: 'frontend/orders/CLEAR_ORDERS', payload: null });
      dispatch(toggleFeedbackPopup({ type: 'success', title: 'delete-success', message: 'Your account has been successfully deleted' }));
      LightboxUtils.removeLightboxVars();
    }
  };
}

export async function saveSitePreference(preference) {
  const state = store.getState();
  const auth = state.auth;
  const preferences = { ...auth.preferences, ...preference };

  const { response } = await post(store.dispatch, '/user/save-site-preference', { preferences });

  if (response.ok) {
    store.dispatch({ type: SAVE_SITE_PREFERENCES, payload: preferences });
  }
  return response;
}
