import algoliasearch from 'algoliasearch/lite';
import get from 'lodash/get';

import { get as apiGet } from 'Api';
import store from 'Redux/store';

export async function getSearchKey(mustFetchAgain = false) {
  const thisDate = Date.now();
  const fetchAgain = (
    mustFetchAgain ||
    !window.searchKey ||
    !window.searchKeyExp ||
    thisDate >= Number(window.searchKeyExp)
  );
  if (fetchAgain) {
    const { response, data } = await apiGet(store.dispatch, '/algolia/key');

    if (response && response.ok) {
      window.searchKey = get(data, 'key', '');
      window.searchKeyExp = get(data, 'validUntil', 0);
    } else {
      window.location.reload();
      return null;
    }
  }
  return window.searchKey;
}

export default async function searchSetup(indexName, isRetry = false) {
  const searchKey = await getSearchKey(isRetry);
  const client = algoliasearch('W2M9492DDV', searchKey);

  if (!indexName) return client;

  const index = client.initIndex(indexName);

  // Clone method before overwriting
  index._search = index.search;

  // Wrapper for search with API key expiry error catch and retry
  index.search = (...args) => index._search(...args)
    .catch(async (ex) => {
      if (ex && ex.message === 'Invalid Application-ID or API key') {
        if (!isRetry) {
          // First error - fetch new key and re-init index
          const freshIndex = await searchSetup(indexName, true);
          return freshIndex.search(...args);
        }

        // Next error, reload with url flag to stop multiple reloads
        const url = window.location.href;
        if (!url.includes('algoliaKeyExpired=1')) {
          window.location.href = `${url}${url.includes('?') ? '&' : '?'}algoliaKeyExpired=1`;

          return false;
        }
      }

      throw ex;
    });

  return index;
}
