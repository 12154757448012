import get from 'lodash/get';

import { countryCode } from 'Helpers/Geo/data';
import { post } from 'Api';
import { setCheckoutMessage, resetCheckoutMessage } from 'Redux/ducks/checkout-message';
import { setVoucherMessage, resetVoucherMessage } from 'Redux/ducks/voucher-message';
import store, { history } from 'Redux/store';
import State from 'Helpers/State';
import GeneralUtils from 'Helpers/GeneralUtils';
import CheckoutUtils from 'Helpers/Checkout/CheckoutUtils';
import { newAPIPayment } from 'Redux/ducks/apipayment';

const CREATE_ORDER = 'frontend/checkout/CREATE_ORDER';
const RESET_CHECKOUT = 'frontend/checkout/RESET_CHECKOUT';

const initialStateCheckout = {
  order: {},
  userId: undefined,
  pageId: undefined,
  countryCode: undefined,
  payment: {},
};

export function resetCheckout() {
  return store.dispatch({ type: RESET_CHECKOUT, payload: null });
}

export function createOrder(body) {
  resetCheckoutMessage();
  resetVoucherMessage();
  return async (dispatch) => {
    const { response, data } = await post(dispatch, '/user/payment/create-order', body);


    if (response.ok) {
      const order = get(data, 'order', initialStateCheckout.order);
      const userId = State.getUserId();
      const pageId = State.getPageId();
      const items = get(order, 'items');
      const completeOrderTransaction = CheckoutUtils.getCompleteOrderTransaction(items);
      const authState = store.getState().auth;
      const roktOptOut = get(authState, 'rokt_opt_out', false);
      const discounts = get(order, 'discounts', []);
      const payment = get(data, 'paymentData.data', initialStateCheckout.payment);

      const payload = {
        order,
        hitType: 'CreateOrder',
        transactionId: get(order, '_id'),
        paymentMethod: get(order, 'paymentMethod', ''),
        transactionTotal: get(order, 'report.total', ''),
        transactionTax: get(completeOrderTransaction, 'transactionTax', ''),
        transactionProducts: get(completeOrderTransaction, 'transactionProducts', ''),
        pageId,
        discountPercent: get(order, 'discounts[0].percent', 0),
        newCustomer: !!data.newCustomer,
        roktOptOut,
        userId,
        countryCode,
        payment,
      };

      if (discounts.length > 0) {
        discounts.forEach((discount) => {
          if (discount.track_to_tapfiliate) {
            payload.track_to_tapfiliate = discount.code;
          }
        });
      }

      const siteBrand = get(order, 'siteBrand');

      if (siteBrand) {
        payload.siteBrand = siteBrand;
      }

      dispatch({ type: CREATE_ORDER, payload });
      return data;
    }

    const type = get(data, 'type', '');
    if (type === 'billing') {
      return data;
    }
    if (type === 'checkout') {
      setCheckoutMessage(data);
    }
    if (type === 'voucher') {
      setVoucherMessage(data);
    }
    resetCheckout();
    return {};
  };
}

export function giveawayOrder(orderId) {
  return async (dispatch) => {
    try {
      const { response, data } = await post(dispatch, '/user/payment/giveaway-order', { orderId });
      if (!response.ok) {
        throw new Error(response.status);
      }
      let authResponse = get(data, 'authResponse', '');
      authResponse = authResponse.toUpperCase();
      const merchantReference = get(data, 'merchantReference', '');
      const pspReference = get(data, 'pspReference', '');
      const url = `/receipt?utm_nooverride=1&authResult=${authResponse}&merchantReference=${merchantReference}&merchantReturnData=false&paymentMethod=false&pspReference=${pspReference}&shopperLocale=en_GB&skinCode=false`;
      history.push(url);
      return null;
    } catch (e) {
      setCheckoutMessage({
        data: {},
        status: 'GIVEAWAY_UNEXPECTED_ERROR',
        type: 'checkout',
        message: 'There was a problem claiming this promotion - please try again or contact support',
      });
      return resetCheckout();
    }
  };
}

export function buyItNow(pidArray) {
  const reduxStore = store.getState();
  const voucher = get(reduxStore, 'voucher.voucher.code', '');
  const shopperLocale = GeneralUtils.getShopperLocale(window.locale);

  const order = {
    snowplowId: GeneralUtils.getSnowplowId(),
    ref_code: GeneralUtils.getReferalCode(),
    shopperLocale,
    code: voucher,
    products: pidArray,
  };

  return async (dispatch) => {
    const { response, data } = await post(dispatch, '/user/payment/buy-now', order);
    if (response.ok) {
      const orderReturned = get(data, 'order', initialStateCheckout.order);
      const userId = State.getUserId();
      const pageId = State.getPageId();
      const payment = get(data, 'paymentData.data', {});
      const apiPaymentData = get(data, 'paymentData', {});
      const items = get(order, 'items');
      const completeOrderTransaction = CheckoutUtils.getCompleteOrderTransaction(items);
      const authState = store.getState().auth;
      const roktOptOut = get(authState, 'rokt_opt_out', false);
      const discounts = get(orderReturned, 'discounts', []);

      const payload = {
        order: orderReturned,
        hitType: 'CreateOrder',
        transactionId: get(orderReturned, '_id'),
        paymentMethod: get(orderReturned, 'paymentMethod', ''),
        transactionTotal: get(orderReturned, 'report.total', ''),
        transactionTax: get(completeOrderTransaction, 'transactionTax', ''),
        transactionProducts: get(completeOrderTransaction, 'transactionProducts', ''),
        pageId,
        discountPercent: get(orderReturned, 'discounts[0].percent', 0),
        newCustomer: !!data.newCustomer,
        roktOptOut,
        userId,
        countryCode,
        payment,
      };

      if (discounts.length > 0) {
        discounts.forEach((discount) => {
          if (discount.track_to_tapfiliate) {
            payload.track_to_tapfiliate = discount.code;
          }
        });
      }

      const siteBrand = get(order, 'siteBrand');

      if (siteBrand) {
        payload.siteBrand = siteBrand;
      }

      dispatch({ type: CREATE_ORDER, payload });
      dispatch(newAPIPayment(apiPaymentData));
      return true;
    }
    if (data.status) {
      return {
        status: data.status,
        message: data.message,
      };
    }
    return true;
  };
}

export function giftProductNow(productId, tierIndex, giftOrder) {
  const reduxStore = store.getState();
  const voucher = get(reduxStore, 'voucher.voucher.code', '');
  const shopperLocale = GeneralUtils.getShopperLocale(window.locale);

  const order = {
    snowplowId: GeneralUtils.getSnowplowId(),
    ref_code: GeneralUtils.getReferalCode(),
    shopperLocale,
    code: voucher,
    productId,
    giftOrder,
    tierIndex,
  };

  return async (dispatch) => {
    const { response, data } = await post(dispatch, '/user/payment/gift-product-now', order);
    if (response.ok) {
      const orderReturned = get(data, 'order', initialStateCheckout.order);
      const userId = State.getUserId();
      const pageId = State.getPageId();
      const payment = get(data, 'paymentData.data', {});
      const apiPaymentData = get(data, 'paymentData', {});
      const items = get(order, 'items');
      const completeOrderTransaction = CheckoutUtils.getCompleteOrderTransaction(items);
      const authState = store.getState().auth;
      const roktOptOut = get(authState, 'rokt_opt_out', false);
      const discounts = get(orderReturned, 'discounts', []);

      const payload = {
        order: orderReturned,
        hitType: 'CreateOrder',
        transactionId: get(orderReturned, '_id'),
        paymentMethod: get(orderReturned, 'paymentMethod', ''),
        transactionTotal: get(orderReturned, 'report.total', ''),
        transactionTax: get(completeOrderTransaction, 'transactionTax', ''),
        transactionProducts: get(completeOrderTransaction, 'transactionProducts', ''),
        pageId,
        discountPercent: get(orderReturned, 'discounts[0].percent', 0),
        newCustomer: !!data.newCustomer,
        roktOptOut,
        userId,
        countryCode,
        payment,
      };

      if (discounts.length > 0) {
        discounts.forEach((discount) => {
          if (discount.track_to_tapfiliate) {
            payload.track_to_tapfiliate = discount.code;
          }
        });
      }

      const siteBrand = get(order, 'siteBrand');

      if (siteBrand) {
        payload.siteBrand = siteBrand;
      }


      dispatch({ type: CREATE_ORDER, payload });
      dispatch(newAPIPayment(apiPaymentData));
      return true;
    }
    if (data.status) {
      return {
        status: data.status,
        message: data.message,
      };
    }
    return true;
  };
}
