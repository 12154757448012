import isEmpty from 'lodash/isEmpty';

import { put } from 'Api';
import { getBySlugsApi } from 'Redux/ducks/algolia-calls';
import store from 'Redux/store';

export const ADD_TO_ANON_WISHLIST = 'frontend/anon-wishlist/ADD_TO_ANON_WISHLIST';

const FETCH_WISHLIST = 'frontend/wishlist/FETCH_WISHLIST';
const FETCH_MORE = 'frontend/wishlist/FETCH_MORE';
const WISHLIST_INTERACTION = 'frontend/wishlist/WISHLIST_INTERACTION';


/**
 * Remove duplicates from an array
 * @param  {Array.string} arr array to remove duplicates from
 * @return {Array.String}     Array returned with duplicates
 */
const removeDuplicates = arr =>
  arr.filter((slug, index) => arr.indexOf(slug) >= index);

/**
 * Get anon wishlist slugs from local storage
 * @return {Array.string} [description]
 */
export function getAnonWishlistSlugs() {
  let anonWishlist = [];
  try {
    anonWishlist = JSON.parse(localStorage.getItem('anonWishlistSlugs')) || [];
  } catch (ex) { /* */ }

  return anonWishlist;
}

/**
 * Fetches anon wishlist data from algolia
 * @return {Object} anon wishlistData dispatch
 */
export function fetchAnonWishlist(_sort, page) {
  return async (dispatch) => {
    const anonWishlistSlugs = getAnonWishlistSlugs();

    if (isEmpty(anonWishlistSlugs)) {
      return dispatch({ type: FETCH_WISHLIST, payload: { items: [], count: 0 } });
    }

    const sort = _sort || localStorage.getItem('wishlistSort');

    const data = await getBySlugsApi(anonWishlistSlugs, sort, page);

    const dispatchData = {
      count: data.nbHits,
      items: data.hits,
      allSlugs: data.allSlugs,
      wishlistLoaded: true,
      page: data.page + 1,
      nbPages: data.nbPages,
    };

    if (page > 1) {
      return dispatch({ type: FETCH_MORE, payload: dispatchData });
    }

    return dispatch({ type: FETCH_WISHLIST, payload: dispatchData });
  };
}

/**
 * Adds or removes an item from the anon wishlist
 * @param  {Object} interaction data related to the wishlist interaction
 * @return {Object} anonWishlistSlugs to dispatch
 */
export function updateAnonWishlistItem(interaction) {
  let anonWishlistSlugs = getAnonWishlistSlugs();
  const inWishlist = !!anonWishlistSlugs.find(s => s === interaction.slug);

  if (inWishlist) anonWishlistSlugs = anonWishlistSlugs.filter(s => s !== interaction.slug);
  else anonWishlistSlugs.unshift(interaction.slug);

  anonWishlistSlugs = removeDuplicates(anonWishlistSlugs).slice(0, 100).filter(Boolean);

  localStorage.setItem('anonWishlistSlugs', JSON.stringify(anonWishlistSlugs));

  store.dispatch({
    type: WISHLIST_INTERACTION,
    payload: {
      productName: interaction.name,
      add: !inWishlist, // if item isn't already on wishlist we're adding
      remove: inWishlist, // if item is already on the wishlist we're removing
      location: window.location.href,
      componentType: interaction.componentType || 'HitCard',
      productId: interaction.productId,
      loggedIn: false,
    },
  });

  return store.dispatch(fetchAnonWishlist());
}

/**
 * Strictly add a product to the anon wishlist if it isn't already on the list
 * @param {String} slug product to add
 * @return {Object} updated anonwishlistData to dispatch
 */
export function addToAnonWishlist(slug) {
  let anonWishlistSlugs = getAnonWishlistSlugs();
  const inWishlist = !!anonWishlistSlugs.find(s => s === slug);

  if (inWishlist) return null;

  anonWishlistSlugs.unshift(slug);

  anonWishlistSlugs = removeDuplicates(anonWishlistSlugs).slice(0, 100).filter(Boolean);

  localStorage.setItem('AnonWishlistSlugs', JSON.stringify(anonWishlistSlugs));

  return store.dispatch(fetchAnonWishlist());
}

/**
 * Used to merge the anon wishlist with a logged in user's wishlist
 */
export async function mergeAnonWishlist() {
  const anonWishlistSlugs = getAnonWishlistSlugs();
  if (isEmpty(anonWishlistSlugs)) return null;

  try {
    const { response, data } = await put([], '/wishlist/merge-anon-wishlist', { slugs: anonWishlistSlugs });
    if (response.ok) {
      localStorage.removeItem('anonWishlistSlugs');
      return data;
    }
  } catch (ex) { /* */ }

  return null;
}
