import { get, put, post } from 'Api';
import store, { history } from 'Redux/store';
import { getAtok } from 'Redux/ducks/orders';
import { toggleGiftModal, toggleRedeemRequestModal, toggleUniversalErrorModal } from 'Redux/ducks/modal';
import { setReturnMethod } from 'Redux/ducks/atok';
import { giftProductNow } from 'Redux/ducks/checkout';
import { universalErrorSet } from 'Redux/ducks/universal-error';

const SET_GIFT_ORDER_DATA = 'frontend/checkout/SET_GIFT_ORDER_DATA';

export async function getGift(code) {
  let gift = {};
  try {
    const language = window.locale || 'en';
    const { response, data } = await get([], `/orders/get-gift/${code}/${language}`);
    if (response.ok) {
      gift = data;
    }
  } catch (ex) { /* */ }

  return gift;
}

export async function acceptGift(code) {
  try {
    const { response, data } = await put([], `/user/orders/accept-gift/${code}`);
    if (response.ok) {
      history.push(`/orders/${data._id}`);
    } else {
      window.location.reload(true);
    }
  } catch (ex) { /* */ }
}

export async function revokeGift(orderId) {
  try {
    const { response } = await put([], `/user/orders/revoke-gift/${orderId}`);
    if (response.ok) {
      window.location.reload(true);
    }
  } catch (ex) { /* */ }
}

export async function convertToGiftAtokCheck(orderId) {
  try {
    const { response, data } = await post([], `/user/orders/atok-check/${orderId}`, { atok: getAtok() });

    if (data.validAtock && response.ok) {
      // following revealkey where setreturnmethod is set the function that calls it
      setReturnMethod(convertToGiftAtokCheck);
      store.dispatch(toggleGiftModal({ giftOrder: {}, orderId, giftAction: 'convert', emailConfirmed: true }));
    } else {
      store.dispatch(toggleRedeemRequestModal());
    }
  } catch (ex) { /* */ }
}

export async function convertToGift(orderId, giftOrder) {
  try {
    const { response } = await put([], `/user/orders/convert-to-gift/${orderId}`, { ...giftOrder, atok: getAtok() });
    if (response.ok) {
      window.location.reload(true);
    }
  } catch (ex) { /* */ }
}

export async function editGift(orderId, giftOrder) {
  try {
    const { response } = await put([], `/user/orders/edit-gift/${orderId}`, giftOrder);
    if (response.ok) {
      window.location.reload(true);
    }
  } catch (ex) { /* */ }
}

export function setGiftOrder(data) {
  store.dispatch({ type: SET_GIFT_ORDER_DATA, payload: data });
}


export async function giftWishlistItem(wishlistId, giftOrder, productId, tierIndex) {
  const paymentData = await store.dispatch(giftProductNow(productId, tierIndex, giftOrder));
  if (paymentData.status && paymentData.message) {
    universalErrorSet({
      status: paymentData.status,
      error: paymentData.message,
    });

    store.dispatch(toggleUniversalErrorModal());
  } else {
    history.push({
      pathname: '/payment',
      state: { buyitnow: true },
    });
  }
}
